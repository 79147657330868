@import '../../styles/customMediaQueries.css';

.root {
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    margin-top: 12px;
  }
}

.hero {
}

.sections {
  margin: 0;
}

.section {
}

.sectionContent {
  margin: 96px 0;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0px;
    margin: 128px 0;
  }
}

.sectionContentFirstChild {
  margin: 48px 0;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin: 72px 0;
    padding: 0px;
  }
}

.sectionContentStatic {
  margin: 96px 0;

  @media (--viewportMedium) {
    margin: 128px 0;
  }
}

.sectionListings {
  margin-top: 72px;
}

.sectionReviews,
.sectionUsers,
.sectionListings,
.sectionHowItWorks,
.sectionStrava {
  /* As we have mobile slider we need to remove 
  padding right on mobiles */
  padding-right: 0px;
  padding-left: 0px;
}
